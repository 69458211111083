
//import './App.css';
import {useState} from 'react';
import Security from './Security';
import Content from './Content';
import axios from 'axios'

function App() {

  
  

  const [domain, setDomain] = useState('');
  const [updated, setUpdated] = useState('');
  const [content, setContent] = useState([]);
  const [security, setSecurity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  
  function isValidDomain(str) {
    // Regex to check valid
    // Domain Name
    let regex = new RegExp(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/);
 
    // if str
    // is empty return false
    if (str == null) {
        return "false";
    }
 
    // Return true if the str
    // matched the ReGex
    if (regex.test(str) == true) {
        return "true";
    }
    else {
        return "false";
    }
}


	const  handleChange = (event) => {
    setMessage("");
		setDomain(event.target.value);
    setSecurity([]);
    setContent([]);
    setUpdated('');
	};

  const handleClick = async (e) => {
  e.preventDefault();
  const verif =  isValidDomain(domain)
  

if (verif === "true") {

    setLoading(true)

try {
   const response = await axios.get(`/api/getcategory/?domain=${domain}`)
    
    let securityArr ="";
    if (response.data.result.hasOwnProperty("risk_type")){
     securityArr = response.data.result.risk_type.filter(object => {
      return object.hasOwnProperty("super_category_id") ; 
      });
    }

    let contentArr = "";
    if(response.data.result.hasOwnProperty("content_categories")){
    contentArr = response.data.result.content_categories.filter(object => {
      return object.hasOwnProperty("super_category_id") ;
      });
    }
    
    setSecurity(securityArr)
    setContent(contentArr)
    setUpdated(domain);
    setDomain('')
    setLoading(false)
   
  } catch(err) {
    setLoading(false)
     setMessage("Cloudflare error: "+err.response.data.errors[0].message)
     

  }
}

  else 
  {
    setMessage("please, enter a valid domain!")
  }

  };




  const Result = () => {
    return <div  className="text-center">
    <b>{updated}</b> is currently categorized as:</div>;
  };

  

  return (
    <div className="App " >
     
   <h2 className="d-flex justify-content-center mt-3 mb-5">Domain categorization</h2>
<div  className="d-flex justify-content-center mb-3">

<form  className="row g-3">
  
  <div className="col-auto">
   
    <input type="text"  id="domain" className="form-control"  value={domain} onChange={handleChange} />
    <label htmlFor="domain" className="mt-3">Search by domain name.</label>
  </div>
  <div className="col-auto">
    <button type="submit" className="btn btn-primary" onClick={handleClick} >Search</button>
  </div>
  
</form>

</div>


<div  className="row d-flex justify-content-center ">
 {message ? <div className="text-danger text-center" >
  {message}
</div>:""}
{loading ?<div className="spinner-border text-primary" role="status">
  <span className="visually-hidden">Loading...</span>
</div>:""}

{updated ?<Result/>:""}
  
  <div className= "text-center">
  
 {security ? <div className="my-2">
   {security.map((obj)=> {
            return (
              <span key={obj.id} className="badge bg-danger mx-3">{obj.name}
        </span>
            )
        })}
</div> : ''}

{content ? <div className="my-2">
    {content.map((obj)=> {
            return (
              <span key={obj.id} className="badge bg-primary mx-3">{obj.name}
              </span>
            )
        })}
        </div> : ''}
      </div>

      {updated ?  
      <div className="container">
           
               
                <div  id="accordion">
                  
                  <div className="row d-flex justify-content-center ">
                    <button className=" btn btn-link my-0 py-0 " type="button" data-bs-toggle="collapse"  href="#collapse1" aria-expanded="false" aria-controls="collapse1">
  Request changes to content categories <span>&#10147;</span>
  </button>
                    </div >

                    <div id="collapse1"
                         className="collapse"
                         data-bs-parent="#accordion">
                        <div >
                           <Content content = {content ? content.map(a => a.name) : [] }  domain={updated}/>
                        </div>
                    </div>


                    <div className="row d-flex justify-content-center ">
                    
                    <button className="btn btn-link my-0 py-0" type="button" data-bs-toggle="collapse" href="#collapse2" aria-expanded="false" aria-controls="collapse2">
  Request changes to security categories <span>&#10147;</span>
  </button>
                    </div>

                    <div id="collapse2"
                         className="collapse"
                         data-bs-parent="#accordion">
                        <div>
                        <Security security = {security ? security.map(a => a.name): []} domain={updated} />
                        </div>
                    </div>
                   
                   
                </div>
           
        </div>


: ""} 
</div>


     


    </div>
  );
}

export default App;