import {categories} from './categories';
import {useState, useRef} from 'react';
import axios from 'axios';


function Content(props) {
 
  const [txtcolor, setColor] = useState('text-danger text-center mt-5 pt-5');
  const [message, setMessage] = useState('');
  const [check , setCheck] = useState(props.content)
  const [counter, setCounter] = useState(props.content.length);
  const [adding, setAdding] = useState([]);
  const [remove, setRemove] = useState([]);
  const [loading, setLoading] = useState(false);
  const ref = useRef([]);


  const resetCheck = () => {
    setMessage('')
   
    for (let i = 0; i < ref.current.length; i++) {
 if (props.content.includes(ref.current[i].value))  ref.current[i].checked = true;
  else ref.current[i].checked = false;
    }

    setCheck(props.content)
    setCounter(props.content.length);
    setAdding([]);
    setRemove([]);

}


const handleSubmit = async () => {
  setMessage("");
  const url =  '/api/requestchange';
  
  
  const data = {
    content_adds:adding.map(x => categories[x]),
    content_removes: remove.map(x => categories[x]),
    indicator_type: 'domain',
    ip: null,
    security_adds: [],
    security_removes: [],
    url: props.domain
  }

  
  
    try {
      setLoading(true)
      const response = await axios.post(url,data);
      
      
      if(response.data.success) {
        setLoading(false);
        setColor("text-success text-center mt-5 pt-5")
        setMessage("Your request has been sent");
        
    } else { 
      setLoading(false);
      setColor("text-danger text-center mt-5 pt-5");
      setMessage("Please, try again later, a problem occured");
              
            }
    } catch (error) {
     
      
      setLoading(false);
      setColor("text-danger text-center mt-5 pt-5");
      setMessage("Cloudflare error: "+error.response.data.errors[0].message)
     
    }
 
  
}  



  function uniqueArray(arr) {
    let outputArray = arr.filter(function (v, i, self) {
 
        // It returns the index of the first
        // instance of each value
        return i == self.indexOf(v);
    });
 
    return outputArray;
}

  const  handleChange = (e) => {
		
    const name = e.target.value;

    
    setMessage("");
      if(e.target.checked) {
        
       setCounter(counter + 1)
       setCheck(uniqueArray([...check, name ]))

        if(!props.content.includes(name)) {
      // filter will remove all elements from props.array in adding array
       setAdding(uniqueArray([...adding, name]).filter( ( el ) => !props.content.includes( el ) ));
       
   
        } else {
          
          setRemove(remove.filter(item => item !== name))
         
        }
      } 
     
      
      else {
        setCounter(counter - 1)
        setCheck(check.filter(item => item !== name))
        if(props.content.includes(name)) {
          setRemove(uniqueArray([...remove, name]))
          
          
          
        }

        else {
          setAdding(adding.filter(item => item !== name))
         

        }
      
      
    
      } 
     
	};


  

return(
    <div className="container">
    <div className="row">
    
   
    <div className="col-md-9 border overflow-scroll  bg-light" 
         style={{maxHeight: "420px", fontSize: "12px"}} >
        
      <div className="d-flex flex-row bd-highlight mb-3">
          
  <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Ads</h5>
        <p>Sites that are providing ads for websites.</p>
        
        <div className="form-check">
        <input ref={(element) => { ref.current[0] = element }}  className="form-check-input" type="checkbox" name="Advertisements" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Advertisements")} defaultChecked={props.content.includes("Advertisements")} value="Advertisements" id="chkAdvertisements"/>
          <label className="form-check-label" htmlFor="chkAdvertisements">Advertisements</label></div>
      </div>
    </div>

  <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Adult Themes</h5>
        <p>Sites that are related to pornography, nudity, sexuality, and other adult themes.</p>
          
        <div className="form-check">
        <input ref={(element) => { ref.current[1] = element }}  className="form-check-input" type="checkbox" name="Adult Themes" onChange={handleChange} value="Adult Themes" disabled = { counter >= 2  && !check.includes("Adult Themes")} defaultChecked={props.content.includes("Adult Themes")}  id="chkAdultThemes"/>
          <label className="form-check-label" htmlFor="chkAdultThemes">Adult Themes</label></div>
         
         <div className="form-check">
        <input ref={(element) => { ref.current[2] = element }}  className="form-check-input" type="checkbox" name="Nudity" onChange={handleChange} value="Nudity" disabled = { counter >= 2  && !check.includes("Nudity")} defaultChecked={props.content.includes("Nudity")} id="chkNudity"/>
          <label className="form-check-label" htmlFor="chkNudity">Nudity</label></div>
         <div className="form-check">
        <input ref={(element) => { ref.current[3] = element }}  className="form-check-input" type="checkbox" name="Pornography" onChange={handleChange} value="Pornography" disabled = { counter >= 2  && !check.includes("Pornography")} defaultChecked={props.content.includes("Pornography")} id="chkPornography"/>
          <label className="form-check-label" htmlFor="chkPornography">Pornography</label></div>
      </div>
    </div>
          
    <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Blocked</h5>
        <p>Sites that should be blocked by default, and whose sub-categories should not be displayed</p>
        <div className="form-check">
        <input ref={(element) => { ref.current[4] = element }} className="form-check-input" type="checkbox" name="Child Abuse" onChange={handleChange} value="Child Abuse" disabled = { counter >= 2  && !check.includes("Child Abuse")} defaultChecked={props.content.includes("Child Abuse")}  id="chkChildAbuse"/>
          <label className="form-check-label" htmlFor="chkChildAbuse">Child Abuse</label></div>
      </div>
    </div>             
  </div>
          
          <div className="d-flex flex-row bd-highlight mb-3">
  <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Business & Economy</h5>
        <p>Sites that are related to business, economy, finance, education, science and technology.</p>
         <div className="form-check">
        <input ref={(element) => { ref.current[5] = element }} className="form-check-input" type="checkbox" name="Business" onChange={handleChange} value="Business" disabled = { counter >= 2  && !check.includes("Business")} defaultChecked={props.content.includes("Business")} id="chkBusiness"/>
          <label className="form-check-label" htmlFor="chkBusiness">Business</label></div>
        
          <div className="form-check">
        <input ref={(element) => { ref.current[6] = element }}  className="form-check-input" type="checkbox" name="Economy & Finance" onChange={handleChange} value="Economy & Finance" disabled = { counter >= 2  && !check.includes("Economy & Finance")} defaultChecked={props.content.includes("Economy & Finance")} id="chkEconomyFinance"/>
          <label className="form-check-label" htmlFor="chkEconomyFinance">Economy & Finance</label></div>
      </div>
    
    </div>
  <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Education</h5>
        <p>Sites related to educational content that are not included in other categories like Science, Technology or Educational institutions.</p>
          
        <div className="form-check">
        <input ref={(element) => { ref.current[7] = element }} className="form-check-input" type="checkbox" name="Education" onChange={handleChange} value="Education" disabled = { counter >= 2  && !check.includes("Education")} defaultChecked={props.content.includes("Education")}  id="chkEducation"/>
          <label className="form-check-label" htmlFor="chkEducation">Education</label></div>
        
          <div className="form-check">
        <input ref={(element) => { ref.current[8] = element }}  className="form-check-input" type="checkbox" name="Educational Institutions" onChange={handleChange} value="Educational Institutions" disabled = { counter >= 2  && !check.includes("Educational Institutions")} defaultChecked={props.content.includes("Educational Institutions")} id="chkEducationalInstitutions"/>
          <label className="form-check-label" htmlFor="chkEducationalInstitutions">Educational Institutions</label></div>
        
          <div className="form-check">
        <input ref={(element) => { ref.current[9] = element }}  className="form-check-input" type="checkbox" name="Science" onChange={handleChange} value="Science" disabled = { counter >= 2  && !check.includes("Science")} defaultChecked={props.content.includes("Science")} id="chkScience"/>
          <label className="form-check-label" htmlFor="chkScience">Science</label></div>
        
          <div className="form-check">
        <input ref={(element) => { ref.current[10] = element }}  className="form-check-input" type="checkbox" name="Space & Astronomy" onChange={handleChange} value="Space & Astronomy" disabled = { counter >= 2  && !check.includes("Space & Astronomy")} defaultChecked={props.content.includes("Space & Astronomy")} id="chkSpaceAstronomy"/>
          <label className="form-check-label" htmlFor="chkSpaceAstronomy">Space & Astronomy</label></div>
      </div>
    
    </div>
          
      <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Entertainment</h5>
        <p>Sites related to entertainment that are not included in other categories like Comic books, Audio streaming, Video streaming, etc.</p>
      
        
      <div className="form-check">
        <input ref={(element) => { ref.current[11] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Arts")} defaultChecked={props.content.includes("Arts")} value="Arts" id="arts"/>
      <label className="form-check-label" htmlFor="arts">Arts</label></div>
    
    
      <div className="form-check">
        <input ref={(element) => { ref.current[12] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Audio Streaming")} defaultChecked={props.content.includes("Audio Streaming")} value="Audio Streaming" id="audioStreaming"/>
      <label className="form-check-label" htmlFor="audioStreaming">Audio Streaming</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[13] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Cartoons & Anime")} defaultChecked={props.content.includes("Cartoons & Anime")} value="Cartoons & Anime" id="cartoonsAnime"/>
      <label className="form-check-label" htmlFor="cartoonsAnime">Cartoons & Anime</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[14] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Comic Books")} defaultChecked={props.content.includes("Comic Books")} value="Comic Books" id="comicBooks"/>
      <label className="form-check-label" htmlFor="comicBooks">Comic Books</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[15] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Entertainment")} defaultChecked={props.content.includes("Entertainment")} value="Entertainment" id="entertainment"/>
      <label className="form-check-label" htmlFor="entertainment">Entertainment</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[16] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Fine Art")} defaultChecked={props.content.includes("Fine Art")} value="Fine Art" id="fineArt"/>
      <label className="form-check-label" htmlFor="fineArt">Fine Art</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[17] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Gaming")} defaultChecked={props.content.includes("Gaming")} value="Gaming" id="gaming"/>
      <label className="form-check-label" htmlFor="gaming">Gaming</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[18] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Home Video/DVD")} defaultChecked={props.content.includes("Home Video/DVD")} value="Home Video/DVD" id="homeVideoDVD"/>
      <label className="form-check-label" htmlFor="homeVideoDVD">Home Video/DVD</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[19] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Humor")} defaultChecked={props.content.includes("Humor")} value="Humor" id="humor"/>
      <label className="form-check-label" htmlFor="humor">Humor</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[20] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Magazines")} defaultChecked={props.content.includes("Magazines")} value="Magazines" id="magazines"/>
      <label className="form-check-label" htmlFor="magazines">Magazines</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[21] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Movies")} defaultChecked={props.content.includes("Movies")} value="Movies" id="movies"/>
      <label className="form-check-label" htmlFor="movies">Movies</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[22] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Music")} defaultChecked={props.content.includes("Music")} value="Music" id="music"/>
      <label className="form-check-label" htmlFor="music">Music</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[23] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("News & Media")} defaultChecked={props.content.includes("News & Media")} value="News & Media" id="newsMedia"/>
      <label className="form-check-label" htmlFor="newsMedia">News & Media</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[24] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Paranormal")} defaultChecked={props.content.includes("Paranormal")} value="Paranormal" id="paranormal"/>
      <label className="form-check-label" htmlFor="paranormal">Paranormal</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[25] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Radio")} defaultChecked={props.content.includes("Radio")} value="Radio" id="radio"/>
      <label className="form-check-label" htmlFor="radio">Radio</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[26] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Television")} defaultChecked={props.content.includes("Television")} value="Television" id="television"/>
      <label className="form-check-label" htmlFor="television">Television</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[27] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Video Streaming")} defaultChecked={props.content.includes("Video Streaming")} value="Video Streaming" id="videoStreaming"/>
      <label className="form-check-label" htmlFor="videoStreaming">Video Streaming</label></div>
          
          
      </div>
    
    </div>      
          
         
      </div>

              <div className="d-flex flex-row bd-highlight mb-3">
  <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Gambling</h5>
        <p>Sites that facilitate online gambling.</p>
        <div className="form-check">
        <input ref={(element) => { ref.current[28] = element }} type="checkbox" name="Gambling" onChange={handleChange} disabled = { counter >= 2  && !check.includes("Gambling")} defaultChecked={props.content.includes("Gambling")} value="Gambling" id="chkGambling" className="form-check-input"/>
          <label htmlFor="chkGambling" className="form-check-label">Gambling</label></div>
      </div>
    
    </div>
  <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Government & Politics</h5>
        <p>Sites related to government and politics.</p>
          
        <div className="form-check">
        <input ref={(element) => { ref.current[29] = element }} type="checkbox" onChange={handleChange} name="Government" disabled = { counter >= 2  && !check.includes("Government")} defaultChecked={props.content.includes("Government")} value="Government" id="chkGovernment" className="form-check-input"/>
            <label htmlFor="chkGovernment" className="form-check-label">Government</label></div>
         <div className="form-check">
        <input ref={(element) => { ref.current[30] = element }} className="form-check-input" type="checkbox" name="Politics" onChange={handleChange} value="Politics, Advocacy, and Government-Related" disabled = { counter >= 2  && !check.includes("Politics, Advocacy, and Government-Related")} defaultChecked={props.content.includes("Politics, Advocacy, and Government-Related")} id="chkPolitics"/>
          <label className="form-check-label checkbox-inline" htmlFor="chkPolitics">Politics, Advocacy, and Government-Related</label></div>
         
      </div>
    
    </div>
          
    <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Health</h5>
        <p>Sites containing information about health and fitness.</p>
     
     <div className="form-check">
        <input ref={(element) => { ref.current[31] = element }} type="checkbox" onChange={handleChange} name="Health & Fitness" disabled = { counter >= 2  && !check.includes("Health & Fitness")} defaultChecked={props.content.includes("Health & Fitness")} value="Health & Fitness" id="chkHealthFitness" className="form-check-input"/>
                            <label htmlFor="chkHealthFitness" className="form-check-label">Health & Fitness</label></div>
       <div className="form-check">
        <input ref={(element) => { ref.current[32] = element }} type="checkbox" onChange={handleChange} name="Sex Education" disabled = { counter >= 2  && !check.includes("Sex Education")} defaultChecked={props.content.includes("Sex Education")} value="Sex Education" id="chkSexEducation" className="form-check-input"/>
                            <label htmlFor="chkSexEducation" className="form-check-label">Sex Education</label></div>
           </div>  
         </div>               
      </div>
          
          
        
          
                      <div className="d-flex flex-row bd-highlight mb-3">
  <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Internet Communication</h5>
        <p>Sites that are used for communication like chat, mail etc.</p>
            <div className="form-check">
        <input ref={(element) => { ref.current[33] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Chat")} defaultChecked={props.content.includes("Chat")} value="Chat" id="chatCheckbox"/>
      <label className="form-check-label" htmlFor="chatCheckbox">
        Chat
      </label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[34] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Forums")} defaultChecked={props.content.includes("Forums")} value="Forums" id="forumsCheckbox"/>
      <label className="form-check-label" htmlFor="forumsCheckbox">
        Forums
      </label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[35] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Information Security")} defaultChecked={props.content.includes("Information Security")} value="Information Security" id="infoSecurityCheckbox"/>
      <label className="form-check-label" htmlFor="infoSecurityCheckbox">
        Information Security
      </label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[36] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Instant Messengers")} defaultChecked={props.content.includes("Instant Messengers")} value="Instant Messengers" id="instantMessengersCheckbox"/>
      <label className="form-check-label" htmlFor="instantMessengersCheckbox">
        Instant Messengers
      </label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[37] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Internet Phone & VOIP")} defaultChecked={props.content.includes("Internet Phone & VOIP")} value="Internet Phone & VOIP" id="internetPhoneVOIPCheckbox"/>
      <label className="form-check-label" htmlFor="internetPhoneVOIPCheckbox">
        Internet Phone & VOIP
      </label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[38] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Messaging")} defaultChecked={props.content.includes("Messaging")} value="Messaging" id="messagingCheckbox"/>
      <label className="form-check-label" htmlFor="messagingCheckbox">
        Messaging
      </label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[39] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("P2P")} defaultChecked={props.content.includes("P2P")} value="P2P" id="p2pCheckbox"/>
      <label className="form-check-label" htmlFor="p2pCheckbox">
        P2P
      </label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[40] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Personal Blogs")} defaultChecked={props.content.includes("Personal Blogs")} value="Personal Blogs" id="personalBlogsCheckbox"/>
      <label className="form-check-label" htmlFor="personalBlogsCheckbox">
        Personal Blogs
      </label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[41] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Photo Sharing")} defaultChecked={props.content.includes("Photo Sharing")} value="Photo Sharing" id="photoSharingCheckbox"/>
      <label className="form-check-label" htmlFor="photoSharingCheckbox">
        Photo Sharing
      </label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[42] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Webmail")} defaultChecked={props.content.includes("Webmail")} value="Webmail" id="webmailCheckbox"/>
      <label className="form-check-label" htmlFor="webmailCheckbox">
      Webmail
      </label></div>
      
      
      
      
      </div>
    
    </div>
  <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Job Search & Careers</h5>
        <p>Sites that facilitate searching for jobs and careers.</p>
          
        <div className="form-check">
        <input ref={(element) => { ref.current[43] = element }} type="checkbox" onChange={handleChange} name="JobSearch" disabled = { counter >= 2  && !check.includes("Job Search & Careers")} defaultChecked={props.content.includes("Job Search & Careers")} value="Job Search & Careers" id="chkJobSearch" className="form-check-input"/>
            <label htmlFor="chkJobSearch" className="form-check-label">Job Search & Careers</label></div>
         
      </div>
    
    </div>
          
    <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Health</h5>
        <p>Military & Weapons</p>
     
     <div className="form-check">
        <input ref={(element) => { ref.current[44] = element }} type="checkbox" onChange={handleChange} name="Military" disabled = { counter >= 2  && !check.includes("Military")} defaultChecked={props.content.includes("Military")} value="Military" id="chkMilitary" className="form-check-input"/>
                            <label htmlFor="chkMilitary" className="form-check-label">Military</label></div>
       <div className="form-check">
        <input ref={(element) => { ref.current[45] = element }} type="checkbox" onChange={handleChange} name="Weapons" disabled = { counter >= 2  && !check.includes("Weapons")} defaultChecked={props.content.includes("Weapons")} value="Weapons" id="chkWeapons" className="form-check-input"/>
                            <label htmlFor="chkWeapons" className="form-check-label">Weapons</label></div>
           </div>  
         </div>               
      </div>  
          
       
        
          
                      <div className="d-flex flex-row bd-highlight mb-3">
  <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Miscellaneous</h5>
        <p>Sites that are not included in the listed security and content categories.</p>
            <div className="form-check">
        <input ref={(element) => { ref.current[46] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Login Screens")} defaultChecked={props.content.includes("Login Screens")} value="Login Screens" id="loginScreensCheckbox"/>
      <label className="form-check-label" htmlFor="loginScreensCheckbox">
        Login Screens
      </label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[47] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Miscellaneous")} defaultChecked={props.content.includes("Miscellaneous")} value="Miscellaneous" id="miscellaneousCheckbox"/>
      <label className="form-check-label" htmlFor="miscellaneousCheckbox">
        Miscellaneous
      </label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[48] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("No Content")} defaultChecked={props.content.includes("No Content")} value= "No Content" id="noContentCheckbox"/>
      <label className="form-check-label" htmlFor="noContentCheckbox">
        No Content
      </label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[49] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Redirect")} defaultChecked={props.content.includes("Redirect")} value="Redirect" id="redirectCheckbox"/>
      <label className="form-check-label" htmlFor="redirectCheckbox">
        Redirect
      </label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[50] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Unreachable")} defaultChecked={props.content.includes("Unreachable")} value="Unreachable" id="unreachableCheckbox"/>
      <label className="form-check-label" htmlFor="unreachableCheckbox">
        Unreachable
      </label></div>
      
      
      </div>
    
    </div>
  <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Questionable Content</h5>
        <p>Sites related to hacking, piracy, profanity and other questionable activities.</p>
          
           <div className="form-check">
        <input ref={(element) => { ref.current[51] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Deceptive Ads")} defaultChecked={props.content.includes("Deceptive Ads")} value="Deceptive Ads" id="deceptiveAdsCheckbox"/>
      <label className="form-check-label" htmlFor="deceptiveAdsCheckbox">
        Deceptive Ads
      </label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[52] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Drugs")} defaultChecked={props.content.includes("Drugs")} value="Drugs" id="drugsCheckbox"/>
      <label className="form-check-label" htmlFor="drugsCheckbox">
        Drugs
      </label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[53] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Hacking")} defaultChecked={props.content.includes("Hacking")} value="Hacking" id="hackingCheckbox"/>
      <label className="form-check-label" htmlFor="hackingCheckbox">
        Hacking
      </label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[54] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Militancy, Hate & Extremism")} defaultChecked={props.content.includes("Militancy, Hate & Extremism")} value="Militancy, Hate & Extremism" id="militancyHateExtremismCheckbox"/>
      <label className="form-check-label" htmlFor="militancyHateExtremismCheckbox">
        Militancy, Hate & Extremism
      </label></div>
  
      <div className="form-check">
        <input ref={(element) => { ref.current[55] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Profanity")} defaultChecked={props.content.includes("Profanity")} value="Profanity" id="profanityCheckbox"/>
      <label className="form-check-label" htmlFor="profanityCheckbox">
        Profanity
      </label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[56] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Questionable Activities")} defaultChecked={props.content.includes("Questionable Activities")} value="Questionable Activities" id="questionableActivitiesCheckbox"/>
      <label className="form-check-label" htmlFor="questionableActivitiesCheckbox">
        Questionable Activities
      </label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[57] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("School Cheating")} defaultChecked={props.content.includes("School Cheating")} value="School Cheating" id="schoolCheatingCheckbox"/>
      <label className="form-check-label" htmlFor="schoolCheatingCheckbox">
        School Cheating
      </label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[58] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Unreliable Information")} defaultChecked={props.content.includes("Unreliable Information")} value="Unreliable Information" id="unreliableInformationCheckbox"/>
      <label className="form-check-label" htmlFor="unreliableInformationCheckbox">
        Unreliable Information
      </label></div>
      </div>
    
    </div>
          
    <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Real Estate</h5>
        <p>Sites related to real estate.</p>
     
     <div className="form-check">
        <input ref={(element) => { ref.current[59] = element }} type="checkbox" onChange={handleChange} name="Health & Fitness" disabled = { counter >= 2  && !check.includes("Real Estate")} defaultChecked={props.content.includes("Real Estate")} value="Real Estate" id="chkReal" className="form-check-input"/>
                            <label htmlFor="chkReal" className="form-check-label">Real Estate</label></div>
       
           </div>  
         </div>               
      </div>  
             
          
          
          
                      <div className="d-flex flex-row bd-highlight mb-3">
  <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Religion</h5>
        <p>Sites related to religion, alternative religion, religious teachings, religious groups and spirituality.</p>
        <div className="form-check">
        <input ref={(element) => { ref.current[60] = element }} type="checkbox" onChange={handleChange} name="Religion" disabled = { counter >= 2  && !check.includes("Religion")} defaultChecked={props.content.includes("Religion")} value="Religion" id="chkReligion" className="form-check-input"/>
          <label htmlFor="chkReligion" className="form-check-label">Religion</label></div>
      </div>
    
    </div>
  <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Safe for Kids</h5>
        <p>Sites that are safe for kids to visit.</p>
          
        <div className="form-check">
        <input ref={(element) => { ref.current[61] = element }} type="checkbox" onChange={handleChange} name="Kids" disabled = { counter >= 2  && !check.includes("Safe for Kids")} defaultChecked={props.content.includes("Safe for Kids")} value="Safe for Kids" id="chkKids" className="form-check-input"/>
            <label htmlFor="chkKids" className="form-check-label">Safe for Kids</label></div>
        
      </div>
    
    </div>
          
    <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Shopping & Auctions</h5>
        <p>Sites related to ecommerce, coupons, shopping, auctions and marketplaces.</p>
     
           <div className="form-check">
        <input ref={(element) => { ref.current[62] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Auctions & Marketplaces")} defaultChecked={props.content.includes("Auctions & Marketplaces")} value="Auctions & Marketplaces" id="auctionsCheckbox"/>
      <label className="form-check-label" htmlFor="auctionsCheckbox">
        Auctions & Marketplaces
      </label></div>
   
    
      <div className="form-check">
        <input ref={(element) => { ref.current[63] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Coupons")} defaultChecked={props.content.includes("Coupons")} value="Coupons" id="couponsCheckbox"/>
      <label className="form-check-label" htmlFor="couponsCheckbox">
        Coupons
      </label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[64] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Ecommerce")} defaultChecked={props.content.includes("Ecommerce")} value="Ecommerce" id="ecommerceCheckbox"/>
      <label className="form-check-label" htmlFor="ecommerceCheckbox">
        Ecommerce
      </label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[65] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Shopping")} defaultChecked={props.content.includes("Shopping")} value="Shopping" id="shoppingCheckbox"/>
      <label className="form-check-label" htmlFor="shoppingCheckbox">
        Shopping
      </label></div>
          
          
          
           </div>  
         </div>               
      </div>  
           
          
        
          
                      <div className="d-flex flex-row bd-highlight mb-3">
  <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Society & Lifestyle</h5>
        <p>Sites related to lifestyle that are not included in other categories like fashion, food & drink etc.</p>
           <div className="form-check">
        <input ref={(element) => { ref.current[66] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Abortion")} defaultChecked={props.content.includes("Abortion")} value="Abortion" id="abortion"/>
      <label className="form-check-label" htmlFor="abortion">Abortion</label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[67] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Alcohol")} defaultChecked={props.content.includes("Alcohol")} value="Alcohol" id="alcohol"/>
      <label className="form-check-label" htmlFor="alcohol">Alcohol</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[68] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Arts & Crafts")} defaultChecked={props.content.includes("Arts & Crafts")} value="Arts & Crafts" id="artsCrafts"/>
      <label className="form-check-label" htmlFor="artsCrafts">Arts & Crafts</label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[69] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Astrology")} defaultChecked={props.content.includes("Astrology")} value="Astrology" id="astrology"/>
      <label className="form-check-label" htmlFor="astrology">Astrology</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[70] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Body Art")} defaultChecked={props.content.includes("Body Art")} value="Body Art" id="bodyArt"/>
      <label className="form-check-label" htmlFor="bodyArt">Body Art</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[71] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Clothing")} defaultChecked={props.content.includes("Clothing")} value="Clothing" id="clothing"/>
      <label className="form-check-label" htmlFor="clothing">Clothing</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[72] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Dating & Relationships")} defaultChecked={props.content.includes("Dating & Relationships")} value="Dating & Relationships" id="datingRelationships"/>
      <label className="form-check-label" htmlFor="datingRelationships">Dating & Relationships</label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[73] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Digital Postcards")} defaultChecked={props.content.includes("Digital Postcards")} value="Digital Postcards" id="digitalPostcards"/>
      <label className="form-check-label" htmlFor="digitalPostcards">Digital Postcards</label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[74] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Fashion")} defaultChecked={props.content.includes("Fashion")} value="Fashion" id="fashion"/>
      <label className="form-check-label" htmlFor="fashion">Fashion</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[75] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Food & Drink")} defaultChecked={props.content.includes("Food & Drink")} value="Food & Drink" id="foodDrink"/>
      <label className="form-check-label" htmlFor="foodDrink">Food & Drink</label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[76] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Hobbies & Interests")} defaultChecked={props.content.includes("Hobbies & Interests")} value="Hobbies & Interests" id="hobbiesInterests"/>
      <label className="form-check-label" htmlFor="hobbiesInterests">Hobbies & Interests</label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[77] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Home & Garden")} defaultChecked={props.content.includes("Home & Garden")} value="Home & Garden" id="homeGarden"/>
      <label className="form-check-label" htmlFor="homeGarden">Home & Garden</label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[78] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Jewelry")} defaultChecked={props.content.includes("Jewelry")} value="Jewelry" id="jewelry"/>
      <label className="form-check-label" htmlFor="jewelry">Jewelry</label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[79] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("LGBTQ")} defaultChecked={props.content.includes("LGBTQ")} value="LGBTQ" id="lgbtq"/>
      <label className="form-check-label" htmlFor="lgbtq">LGBTQ</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[80] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Lifestyle")} defaultChecked={props.content.includes("Lifestyle")} value="Lifestyle" id="lifestyle"/>
      <label className="form-check-label" htmlFor="lifestyle">Lifestyle</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[81] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Lingerie & Bikini")} defaultChecked={props.content.includes("Lingerie & Bikini")} value="Lingerie & Bikini" id="lingerieBikini"/>
      <label className="form-check-label" htmlFor="lingerieBikini">Lingerie & Bikini</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[82] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Parenting")} defaultChecked={props.content.includes("Parenting")} value="Parenting" id="parenting"/>
      <label className="form-check-label" htmlFor="parenting">Parenting</label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[83] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Pets")} defaultChecked={props.content.includes("Pets")} value="Pets" id="pets"/>
      <label className="form-check-label" htmlFor="pets">Pets</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[84] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Photography")} defaultChecked={props.content.includes("Photography")} value="Photography" id="photography"/>
      <label className="form-check-label" htmlFor="photography">Photography</label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[85] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Professional Networking")} defaultChecked={props.content.includes("Professional Networking")} value="Professional Networking" id="professionalNetworking"/>
      <label className="form-check-label" htmlFor="professionalNetworking">Professional Networking</label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[86] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Sexuality")} defaultChecked={props.content.includes("Sexuality")} value="Sexuality" id="sexuality"/>
      <label className="form-check-label" htmlFor="sexuality">Sexuality</label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[87] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Social Networks")} defaultChecked={props.content.includes("Social Networks")} value="Social Networks" id="socialNetworks"/>
      <label className="form-check-label" htmlFor="socialNetworks">Social Networks</label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[88] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Swimsuits")} defaultChecked={props.content.includes("Swimsuits")} value="Swimsuits" id="swimsuits"/>
      <label className="form-check-label" htmlFor="swimsuits">Swimsuits</label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[89] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Tobacco")} defaultChecked={props.content.includes("Tobacco")} value="Tobacco" id="tobacco"/>
      <label className="form-check-label" htmlFor="tobacco">Tobacco</label></div>
      
      
      </div>
    
    </div>
  <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Sports</h5>
        <p>Sites related to sports & recreation.</p>
          
        <div className="form-check">
        <input ref={(element) => { ref.current[90] = element }} type="checkbox" onChange={handleChange} name="Sports" disabled = { counter >= 2  && !check.includes("Sports")} defaultChecked={props.content.includes("Sports")} value="Sports" id="chkSports" className="form-check-input"/>
            <label htmlFor="chkSports" className="form-check-label">Sports</label></div>
      </div>
    
    </div>
          
    <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Technology</h5>
        <p>Sites related to technology that are not included in the science category.</p>
     
      <div className="form-check">
        <input ref={(element) => { ref.current[91] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("APIs")} defaultChecked={props.content.includes("APIs")} value="APIs" id="apis"/>
      <label className="form-check-label" htmlFor="apis">APIs</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[92] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Content Servers")} defaultChecked={props.content.includes("Content Servers")} value="Content Servers" id="contentServers"/>
      <label className="form-check-label" htmlFor="contentServers">Content Servers</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[93] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("File Sharing")} defaultChecked={props.content.includes("File Sharing")} value="File Sharing" id="fileSharing"/>
      <label className="form-check-label" htmlFor="fileSharing">File Sharing</label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[94] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Information Technology")} defaultChecked={props.content.includes("Information Technology")} value="Information Technology" id="informationTechnology"/>
      <label className="form-check-label" htmlFor="informationTechnology">Information Technology</label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[95] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("News, Portal & Search")} defaultChecked={props.content.includes("News, Portal & Search")} value="News, Portal & Search" id="newsPortalSearch"/>
      <label className="form-check-label" htmlFor="newsPortalSearch">News, Portal & Search</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[96] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Search Engines")} defaultChecked={props.content.includes("Search Engines")} value="Search Engines" id="searchEngines"/>
      <label className="form-check-label" htmlFor="searchEngines">Search Engines</label></div>
    
      <div className="form-check">
        <input ref={(element) => { ref.current[97] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Technology")} defaultChecked={props.content.includes("Technology")} value="Technology" id="technology"/>
      <label className="form-check-label" htmlFor="technology">Technology</label></div>
   
      <div className="form-check">
        <input ref={(element) => { ref.current[98] = element }} className="form-check-input" type="checkbox" onChange={handleChange}  disabled = { counter >= 2  && !check.includes("Translator")} defaultChecked={props.content.includes("Translator")} value="Translator" id="translator"/>
      <label className="form-check-label" htmlFor="translator">Translator</label></div>
           
          
           </div>  
         </div>               
      </div>  
          
         
          
                      <div className="d-flex flex-row bd-highlight mb-3">
  <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Travel</h5>
        <p>Sites that contain information about listings, reservations, services for travel.</p>
        <div className="form-check">
        <input ref={(element) => { ref.current[99] = element }} type="checkbox" onChange={handleChange} name="Travel" disabled = { counter >= 2  && !check.includes("Travel")} defaultChecked={props.content.includes("Travel")} value="Travel" id="chkTravel" className="form-check-input"/>
          <label htmlFor="chkTravel" className="form-check-label">Travel</label></div>
      </div>
    
    </div>
  <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Vehicles</h5>
        <p>Sites related to vehicles, automobiles, including news, reviews, and other hobbyist information.</p>
          
        <div className="form-check">
        <input ref={(element) => { ref.current[100] = element }} type="checkbox" onChange={handleChange} name="Vehicles" disabled = { counter >= 2  && !check.includes("Vehicles")} defaultChecked={props.content.includes("Vehicles")} value="Vehicles" id="chkVehicles" className="form-check-input"/>
            <label htmlFor="chkVehicles" className="form-check-label">Vehicles</label></div>
         
      </div>
    
    </div>
          
    <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Violence</h5>
        <p>Sites displaying or promoting violent content.</p>
     
     <div className="form-check">
        <input ref={(element) => { ref.current[101] = element }} type="checkbox" onChange={handleChange} name="Violence" disabled = { counter >= 2  && !check.includes("Violence")} defaultChecked={props.content.includes("Violence")} value="Violence" id="chkViolence" className="form-check-input"/>
        <label htmlFor="chkViolence" className="form-check-label">Violence</label></div>
    
         </div>               
      </div>  
        </div>  
         
          
 <div className="d-flex flex-row bd-highlight mb-3">
  <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Weather</h5>
        <p>Sites related to weather.</p>
        <div className="form-check">
        <input ref={(element) => { ref.current[102] = element }} type="checkbox" onChange={handleChange} name="Weather" disabled = { counter >= 2  && !check.includes("Weather")} defaultChecked={props.content.includes("Weather")} value="Weather" id="chkWeather" className="form-check-input"/>
          <label htmlFor="chkWeather" className="form-check-label">Weather</label></div>
          </div>
        </div>  
    <div className="p-2 bd-highlight">
      <div className="col">
        <h5>CIPA</h5>
        <p>Sites related to aiding schools and organizations in abiding by CIPA requirements.</p>
        <div className="form-check">
        <input ref={(element) => { ref.current[103] = element }} type="checkbox" onChange={handleChange} name="CIPA Filter" disabled = { counter >= 2  && !check.includes("CIPA Filter")} defaultChecked={props.content.includes("CIPA Filter")} value="CIPA Filter" id="chkCIPA" className="form-check-input"/>
          <label htmlFor="chkCIPA" className="form-check-label">CIPA Filter</label></div>
          </div>
        </div>  

	
      </div>  
 




 </div>




        
        
        
        
      <div className="col-md-3 border">
       
        
     

<div className="h-100  d-flex flex-column">
      <div className="align-top text-center mt-2">
        <h5>Requested Changes</h5>
      </div>
      <div>
      {adding.length ? <div className="my-2">
       Added:<br/>
   {adding.map((obj, id)=> {
            return (
              <div key={id} className="badge bg-primary mx-3">{obj}
        </div>
            )
        })}
</div> : ''}

{remove.length ? <div className="my-2">
        Removed:<br/>
   {remove.map((obj,id)=> {
            return (
              <div key={id} className="badge bg-primary mx-3">{obj}
        </div>
            )
        })}
</div> : ''}


      </div>
      
      <div className="text-center">
      {loading ?<div className="spinner-border text-primary mt-2" role="status">
  <span className="visually-hidden">Loading...</span>
</div>:""}
     </div>
      {message ? <div className= {txtcolor} >
  <small>{message}</small>  </div>:""}


      <div className="flex-grow-1 d-flex justify-content-center align-items-end">
     
     
      
  
 
<div className="btn-group mb-2" role="group">                
                 <button id="submit" name="button" onClick={ handleSubmit}  value="register" className="btn btn-primary m-2 rounded">Submit</button>               
                 <button className="btn btn-secondary m-2 rounded" type="button" data-bs-toggle="collapse" href="#collapse1" aria-expanded="false" onClick={resetCheck} aria-controls="collapse1">Cancel</button>
                       
      </div>
      
      </div>
    </div>
    
    
 
          
          
          
      </div>
    </div>
  </div>
 
)


}
export default Content;
