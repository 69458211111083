export const  categories  = {
    "Adult Themes": 67,
    "Nudity": 125,
    "Pornography": 133,
    "Business": 75,
    "Economy & Finance": 89,
    "Education": 90,
    "Educational Institutions": 91,
    "Science": 144,
    "Space & Astronomy": 150,
    "Arts": 70,
    "Audio Streaming": 74,
    "Cartoons & Anime": 76,
    "Comic Books": 79,
    "Entertainment": 92,
    "Fine Art": 96,
    "Gaming": 100,
    "Home Video/DVD": 106,
    "Humor": 107,
    "Magazines": 116,
    "Movies": 120,
    "Music": 121,
    "News & Media": 122,
    "Paranormal": 127,
    "Radio": 139,
    "Television": 156,
    "Video Streaming": 164,
    "Gambling": 99,
    "Government": 101,
    "Politics, Advocacy, and Government-Related": 137,
    "Health & Fitness": 103,
    "Sex Education": 146,
    "Chat": 77,
    "Forums": 98,
    "Information Security": 108,
    "Instant Messengers": 110,
    "Internet Phone & VOIP": 111,
    "Messaging": 118,
    "P2P": 126,
    "Personal Blogs": 129,
    "Webmail": 168,
    "Photo Sharing": 172,
    "Job Search & Careers": 113,
    "Login Screens": 115,
    "Miscellaneous": 119,
    "No Content": 124,
    "Redirect": 141,
    "Unreachable": 161,
    "Deceptive Ads": 85,
    "Drugs": 87,
    "Hacking": 102,
    "Profanity": 135,
    "Questionable Activities": 138,
    "Militancy, Hate & Extremism": 157,
    "Unreliable Information": 162,
    "Real Estate": 140,
    "Religion": 142,
    "Safe for Kids": 143,
    "Anonymizer": 68,
    "Command and Control & Botnet": 80,
    "Cryptomining": 83,
    "Malware": 117,
    "Phishing": 131,
    "Private IP Address": 134,
    "Spam": 151,
    "Spyware": 153,
    "DNS Tunneling": 175,
    "Domain Generation Algorithm": 176,
    "Typosquatting & Impersonation": 178,
    "Auctions & Marketplaces": 73,
    "Coupons": 82,
    "Ecommerce": 88,
    "Shopping": 148,
    "Arts & Crafts": 71,
    "Astrology": 72,
    "Clothing": 78,
    "Dating & Relationships": 84,
    "Digital Postcards": 86,
    "Parenting": 93,
    "Fashion": 94,
    "Food & Drink": 97,
    "Hobbies & Interests": 104,
    "Home & Garden": 105,
    "Lifestyle": 114,
    "Pets": 130,
    "Photography": 132,
    "Professional Networking": 136,
    "Sexuality": 147,
    "Social Networks": 149,
    "Swimsuits": 154,
    "Tobacco": 158,
    "Body Art": 173,
    "Lingerie & Bikini": 174,
    "Sports": 152,
    "Technology": 155,
    "File Sharing": 95,
    "APIs": 69,
    "Content Servers": 81,
    "Information Technology": 109,
    "News, Portal & Search": 123,
    "Search Engines": 145,
    "Translator": 159,
    "Travel": 160,
    "Vehicles": 163,
    "Violence": 165,
    "Weapons": 166,
    "Weather": 167,
    "Child Abuse": 170,
    "Parked & For Sale Domains": 128,
    "New Domains": 169,
    "Newly Seen Domains": 177,
    "CIPA Filter": 182
  }
