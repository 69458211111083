import {categories} from './categories';
import {useState, useRef} from 'react';
import axios from 'axios';



function Security(props) {
  const [txtcolor, setColor] = useState('setColor("text-danger text-center mt-5 pt-5")');
  const [message, setMessage] = useState('');
  const [check , setCheck] = useState(props.security)
  const [counter, setCounter] = useState(props.security.length);
  const [adding, setAdding] = useState([]);
  const [remove, setRemove] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const ref = useRef([]);

  const resetCheck = () => {
    setMessage('')

    for (let i = 0; i < ref.current.length; i++) {
 if (props.security.includes(ref.current[i].value))
        ref.current[i].checked = true;
  else ref.current[i].checked = false;
    }

    setCheck(props.security)
    setCounter(props.security.length);
    setAdding([]);
    setRemove([]);

}


  function uniqueArray(arr) {
    let outputArray = arr.filter(function (v, i, self) {
 
        // It returns the index of the first
        // instance of each value
        return i == self.indexOf(v);
    });
 
    return outputArray;
}




const handleSubmit = async () => {
  setMessage("");
  const url =  '/api/requestchange';

  const data = {
    content_adds: [],
    content_removes: [],
    indicator_type: 'domain',
    ip: null,
    security_adds: adding.map(x => categories[x]),
    security_removes: remove.map(x => categories[x]),
    url: props.domain
  }
  

    try {
      setLoading(true)
      const response = await axios.post(url,data);
     
     
      if(response.data.success) {
        setLoading(false)
        setColor("text-success text-center mt-5 pt-5");
        setMessage("Your request has been sent");
       
    
    } else { 
      setLoading(false);
      setColor("text-danger text-center mt-5 pt-5");
    setMessage("Please, try again later, a problem occured");
      }
    } catch (error) {
      setLoading(false);
      setColor("text-danger text-center mt-5 pt-5");
      setMessage("Cloudflare error: "+error.response.data.errors[0].message)
      
    }
 
  
}  

  const  handleChange = (e) => {
		
    const name = e.target.value;
    setMessage("");
      if(e.target.checked) {
        
       setCounter(counter + 1)
       setCheck(uniqueArray([...check, name ]))

        if(!props.security.includes(name)) {
      // filter will remove all elements from props.array in adding array
       setAdding(uniqueArray([...adding, name]).filter( ( el ) => !props.security.includes( el ) ));
       
   
        } else {
          
          setRemove(remove.filter(item => item !== name))
         
        }
      } 
     
      
      else {
        setCounter(counter - 1)
        setCheck(check.filter(item => item !== name))
        if(props.security.includes(name)) {
          setRemove(uniqueArray([...remove, name]))
          
          
          
        }

        else {
          setAdding(adding.filter(item => item !== name))
         

        }
      
      
    
      } 
     
	};


  


return(
    <div className="container">
    <div className="row">
    <div className="col-md-9 border overflow-scroll  bg-light" 
         style={{maxHeight: "420px", fontSize: "12px"}} >
        
        
       
      <div className="d-flex flex-row bd-highlight mb-3">
  <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Security Risks</h5>
          <p>Sites that are not confirmed to be malicious but have a higher likelihood of containing security threats.
</p>


        
         <div className="form-check">
        <input ref={(element) => { ref.current[0] = element }} type="checkbox" id="newDomains" className="form-check-input " onChange={handleChange}    disabled = { counter >= 2  && !check.includes("New Domains")} defaultChecked={props.security.includes("New Domains")}  value="New Domains"/>
            
          <label htmlFor="newDomains" className="form-check-label">New Domains</label>
          </div>
        
         <div className="form-check">
        <input ref={(element) => { ref.current[1] = element }} type="checkbox" id="newlySeenDomains" className="form-check-input" onChange={handleChange} disabled = { counter >= 2  && !check.includes("Newly Seen Domains")} defaultChecked={props.security.includes("Newly Seen Domains")} value="Newly Seen Domains"/>
          <label htmlFor="newlySeenDomains" className="form-check-label"> Newly Seen Domains</label>
          </div>
        
         <div className="form-check">
        <input ref={(element) => { ref.current[2] = element }} type="checkbox" id="parkedAndForSaleDomains" className="form-check-input" onChange={handleChange} disabled = { counter >= 2  && !check.includes("Parked & For Sale Domains")  } defaultChecked={props.security.includes("Parked & For Sale Domains")} value="Parked & For Sale Domains" /> 
          <label htmlFor="parkedAndForSaleDomains" className="form-check-label">Parked & For Sale Domains</label></div>
      </div>
    
    </div>
  <div className="p-2 bd-highlight">
      <div className="col">
        <h5>Security Threats</h5>
          
         <p>Sites that contain security threats like malware, phishing, cryptomining and other security threats.</p> 
          
       
         <div className="form-check">
        <input ref={(element) => { ref.current[3] = element }} type="checkbox" id="anonymizer" className="form-check-input" onChange={handleChange} disabled = {  counter >= 2  && !check.includes("Anonymizer")} defaultChecked={props.security.includes("Anonymizer")} value="Anonymizer"/>
          <label htmlFor="anonymizer" className="form-check-label"> Anonymizer</label></div>
        
         <div className="form-check">
        <input ref={(element) => { ref.current[4] = element }} type="checkbox" id="brandEmbedding" className="form-check-input" onChange={handleChange} disabled = {  counter >= 2  && !check.includes("Brand Embedding")} defaultChecked={props.security.includes("Brand Embedding")} value="Brand Embedding"/> 
          <label htmlFor="brandEmbedding" className="form-check-label">Brand Embedding</label></div>
        
         <div className="form-check">
        <input ref={(element) => { ref.current[5] = element }} type="checkbox" id="commandAndControlBotnet" className="form-check-input" onChange={handleChange} disabled = {  counter >= 2 && !check.includes("Command and Control & Botnet")} defaultChecked={props.security.includes("Command and Control & Botnet")} value="Command and Control & Botnet"/> 
          <label htmlFor="commandAndControlBotnet" className="form-check-label">Command and Control & Botnet</label></div>
       
         <div className="form-check">
        <input ref={(element) => { ref.current[6] = element }} type="checkbox" id="cryptomining" className="form-check-input" onChange={handleChange} disabled = {  counter >= 2  && !check.includes("Cryptomining")} defaultChecked={props.security.includes("Cryptomining")} value="Cryptomining"/> 
          <label htmlFor="cryptomining" className="form-check-label">Cryptomining</label></div>
       
         <div className="form-check">
        <input ref={(element) => { ref.current[7] = element }} type="checkbox" id="dgaDomains" className="form-check-input" onChange={handleChange} disabled = {  counter >= 2  && !check.includes("DGA Domains") } defaultChecked={props.security.includes("DGA Domains")} value="DGA Domains"/> 
          <label htmlFor="dgaDomains" className="form-check-label">DGA Domains</label></div>
       
         <div className="form-check">
        <input ref={(element) => { ref.current[8] = element }} type="checkbox" id="dnsTunneling" className="form-check-input" onChange={handleChange} disabled = {  counter >= 2  && !check.includes("DNS Tunneling") } defaultChecked={props.security.includes("DNS Tunneling")} value="DNS Tunneling"/> 
          <label htmlFor="dnsTunneling" className="form-check-label">DNS Tunneling</label></div>
       
         <div className="form-check">
        <input ref={(element) => { ref.current[9] = element }} type="checkbox" id="malware" className="form-check-input" onChange={handleChange} disabled = {  counter >= 2  && !check.includes("Malware")} defaultChecked={props.security.includes("Malware")} value="Malware"/>
          <label htmlFor="malware" className="form-check-label">Malware</label></div>
        
         <div className="form-check">
        <input ref={(element) => { ref.current[10] = element }} type="checkbox" id="phishing" className="form-check-input" onChange={handleChange} disabled = {  counter >= 2  && !check.includes("Phishing") } defaultChecked={props.security.includes("Phishing")} value="Phishing"/> 
          <label htmlFor="phishing" className="form-check-label">Phishing</label></div>
        
         <div className="form-check">
        <input ref={(element) => { ref.current[11] = element }} type="checkbox" id="privateIpAddress" className="form-check-input" onChange={handleChange} disabled = {  counter >= 2  && !check.includes("Private IP Address")} defaultChecked={props.security.includes("Private IP Address")} value="Private IP Address"/> 
          <label htmlFor="privateIpAddress" className="form-check-label">Private IP Address</label></div>
        
         <div className="form-check">
        <input ref={(element) => { ref.current[12] = element }} type="checkbox" id="spam" className="form-check-input" onChange={handleChange} disabled = {  counter >= 2  && !check.includes("Spam")} defaultChecked={props.security.includes("Spam")} value="Spam"/> 
          <label htmlFor="spam" className="form-check-label">Spam</label></div>
        
         <div className="form-check">
        <input ref={(element) => { ref.current[13] = element }} type="checkbox" id="spyware" className="form-check-input" onChange={handleChange} disabled = {  counter >= 2  && !check.includes("Spyware") } defaultChecked={props.security.includes("Spyware")} value="Spyware"/> 
          <label htmlFor="spyware" className="form-check-label">Spyware</label></div>
      </div>
    
    
    
    
    </div>
    
          
          
      </div>
         
          
      </div>
        
        
        
        
      <div className="col-md-3 border">
       
        
     

<div className="h-100  d-flex flex-column">
      <div className="align-top text-center mt-2">
        <h5>Requested Changes</h5>
      </div>
      <div>
      {adding.length ? <div className="my-2">
       Added:<br/>
   {adding.map((obj, id)=> {
            return (
              <div key={id} className="badge bg-danger mx-3">{obj}
        </div>
            )
        })}
</div> : ''}

{remove.length ? <div className="my-2">
        Removed:<br/>
   {remove.map((obj,id)=> {
            return (
              <div key={id} className="badge bg-danger mx-3">{obj}
        </div>
            )
        })}
</div> : ''}


      </div>

      <div className="text-center">
      {loading ?<div className="spinner-border text-primary mt-2" role="status">
  <span className="visually-hidden">Loading...</span>
</div>:""}
       </div>
      {message ? <div className={txtcolor} >
  <small>{message}</small>  </div>:""}
     
     
      
      <div className="align-bottom flex-grow-1 d-flex justify-content-center align-items-end">
        
     
<div className="btn-group mb-2" role="group">                
                 <button id="submit" name="button" onClick={handleSubmit}  value="register" className="btn btn-primary m-2 rounded">Submit</button>               
                
                <button className="btn btn-secondary m-2 rounded" type="button" data-bs-toggle="collapse" href="#collapse2" aria-expanded="false" aria-controls="collapse2" onClick={resetCheck}>Cancel</button>          
      </div>
      </div>
    </div>
    
      </div>
    </div>
  </div>
 
)


}
export default Security;
